import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPencilAlt, faShoppingCart, faUniversity, faPrint, faShareAlt, faUser, faEnvelope, faThumbsUp, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

import Container from "../components/layout/Container"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Loading from "../components/Loading"
import MajorError from "../components/MajorError"
import SignPreviewImage from "../components/SignPreviewImage"
import SignDetails from "../components/SignDetails"
import TextField from "../components/fields/TextField"
import Button from "../components/buttons/Button";
import HoverButton from "../components/buttons/HoverButton";

import { formatPrice } from "../utilities/utils"
import { postData } from '../services/server'
import { CartContext } from '../state/CartContext';
import { colors, devices, shipping, storage } from "../settings";
import { copyTextToClipboard } from "../utilities/utils";
import { isEmailAddress } from "../utilities/validation";
import { getSignProductionDays } from "../services/sign";
import ShipDate from "../components/ShipDate";
import Breadcrumb from "../components/layout/Breadcrumb";
import Layout2Columns from "../components/layout/Layout2Columns";

const SignMenu = styled.ul`
  flex: 0;
  flex-basis: 270px;
  margin: 0px;
  padding: 0px 15px 15px 15px;
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media ${devices.mobile} {
    padding: 5px;
  }
`
const MenuItems = styled.li`
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: flex;

    li {
      flex-basis: 50%;
    }
  }
`

const MenuItem = styled.li`
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px;
  text-align: center;

  border-color: ${props => props.color ? colors[props.color] : "#ccc"};
  color: ${props => props.color ? colors[props.color + "Text"] : "#666"};
  
  &:hover {
    background-color: ${props => props.color ? colors[props.color] : props.nohover ? "unset" : "#666"};

    a {
      color: ${props => props.nohover ? "unset": "white"};
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  a {
    color: ${props => props.color ? colors[props.color] : "inherit"};
    flex-basis: 100%;  
  }

  > div {
    margin: 0px auto;

    > div {
      margin-top: 15px;

      > div {
        display: flex;

        svg {
          margin: 0px;
        }
        button {
          width: 60px;
        }
      }
    }
  }
`
const Price = styled.strong`
  font-size: 30px;
  font-weight: 400;
`
const ExpiredPrice = styled.strong`
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  color: red;
`
const NotApprovedPrice = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: red;
  padding: 0px 10px;
`
const SignPreview = styled.div`
  border-radius: 5px;
  min-height: 310px;

  @media ${devices.mobile} {
    min-height: auto;
  }
`
const Content = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  padding-top: 25px;
  margin-top: 100px;
`


const SignPage = (props) => {
  const cart = useContext(CartContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [sign, setSign] = useState(null);
  const [signProductionDays, setSignProductionDays] = useState(shipping.productionDays);
  const [showShipDateDisclaimer, setShowShipDateDisclaimer] = useState(false);
  const [user, setUser] = useState(null);
  const [share, setShare] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [shared, setShared] = useState(false);

  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")
  const signLink = "https://signmonkey.com/sign/?" + (params.uid ? "uid=" + params.uid : params.id ? "id=" + params.id : "") ;

  const [data, setData] = useState({
		link: signLink,
		email: "",
  });
  const [errors, setErrors] = useState({
	});

  function onFieldChange(e) {
    setError(null);
    setShared(false);
    setData({...data, [e.target.name]: e.target.value});
  }

  function validateForm() {
    const emailValid = data.email && data.email.length > 0 && isEmailAddress(data.email);

    setErrors({
			email: !emailValid,
    });

    return emailValid;
  }
  
  useEffect(
    () => {
        setLoading(true);

        const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

        if (params.uid || params.id) {
          postData("/api/builder/signdata" + (params.id ? "?id=" + params.id:""), params,
              (result) => {
                  for (const group of result.groups) {
                    if (group.parts.filter(p => p.type_name == "backdrop").length > 0) {
                      group.backdrop_ind = 1;
                    }
                  }

                  setSign({
                    backdrop_ind: result.groups.filter(g => g.backdrop_ind == 1).length > 0 ? 1 : 0,
                    ...result
                  });   
                  setSignProductionDays(getSignProductionDays(result));             
              },
              (error, status) => {
                if ((error.response && error.response.status == 404) || status == 404)
                  setError("Unable to locate the requested sign.");
                else
                  setError("An error occured while loading the sign.");
              },
              () => {
                  setLoading(false);
              }
          ); 
        }
        else setError("Invalid sign.")                 
    }, 
    []
  );

  function onAddToCart() {
    setAdding(true);

    cart.addSignToCart(sign.id, (cart) => {
        if (cart) {
            setTimeout(() => {
                navigate("/checkout/?returnto=mysigns");
              },
              200
            );
        }
        else {
          setError("An error occured adding the sign to your cart.")
        }

        setAdding(false);
    })
  }
  function onCalcPrice() {
    setAdding(true);

    postData("/api/signs/updateprice", { sign_id: sign.id},
        function(response) {
            setSign(response);
        },
        function(error) {
            window.alert("An error occured generating the price.");
        },
        function() {
            setAdding(false);
        }
    );
  }
  function onCopyLink() {
    copyTextToClipboard(signLink);
  }

  function onShareLink() {
    const valid = validateForm();

    if (valid) {
      setSharing(true)

      postData("/api/signs/share", {id: sign.id, email: data.email},
          () => {
            setShared(true);
          },
          (error) => {
            console.log(error)
            alert("An error occured while sharing the sign.");
          },
          () => {
            setSharing(false);
          }
      ); 
    }
  }

  return (
    <>
      <SEO 
        page="sign" 
        title={sign ? "Sign Design #"+sign.id : "Sign Details"} 
        meta={[
          {name: "og:image", content: sign ? storage.url + sign.preview_url : ""}
        ]}
      />

      <Container>
        <Breadcrumb items={[
            { url: "/", title: "Home"},
            { url: "/account/signs", title: "My Signs"},
            { url: null, title: "Sign Details" }
          ]}
        />
      </Container>

        { loading ?
            <Loading label="Loading sign..." style={{margin: "50px" }} />
          : sign ?
            <>  
              <Container>
                <PageHeader 
                      title={sign ? "Sign Design #" + sign.id : "Loading Sign..."} 
                      smalltitle={sign ? " V" + sign.version + (sign.title ? " - " + sign.title : "") : ""}
                      subtitle={sign && 
                        "Created on <strong>" + sign.added_date_formatted + "</strong> " +
                        `Estimated Size: <strong>${sign.size}</strong> ${sign.backdrop_ind == 1 && !sign.overall_size_height && !sign.overall_size_width ? " <span style='color: red;'>* Call To Confirm</span>":""}` + 
                        (sign.overall_size_height && sign.overall_size_width ? `<strong>(${sign.overall_size_height} x ${sign.overall_size_width} Overall)</strong>, ` : ", ") + 
                        sign.area + " square feet"
                      }
                    />

                  <Layout2Columns align="top" left={70} right={30}>
                    <div>
                      <SignPreview>
                        <SignPreviewImage root src={sign.preview_url} responsive style={{ height: "auto", maxWidth: "100%", maxHeight: "400px", display: "block", margin: "10px auto" }} />
                      </SignPreview>
                    </div>
                    <div>
                      <SignMenu>
                        <MenuItem color="white" style={{ paddingRight: "0px"}}>
                          {sign.estimate_expire_days < 0 ? 
                            <ExpiredPrice>Price Expired</ExpiredPrice>
                          :
                            <>
                              <Price>
                                { sign && formatPrice(sign.estimate) }
                                {sign.estimate_approved_ind != 1 && 
                                    <span style={{color:"red", marginLeft: "4px"}}>*</span>
                                }
                              </Price>
                            </>
                          }
                          {sign.estimate_approved_ind == 1 &&
                            <HoverButton 
                                label={sign.estimate_expire_days < 0 ? "Get Price" : "Buy"}
                                color="green"
                                wide
                                style={{marginLeft: "15px"}}
                                busy={adding}
                                icon={sign.estimate_expire_days < 0 ? faDollarSign : faShoppingCart}
                                onClick={sign.estimate_expire_days < 0 ? onCalcPrice : onAddToCart}
                            />
                          }
                        </MenuItem>

                        {sign.estimate_approved_ind != 1 && 
                            <NotApprovedPrice>* Price is just an estimate and is being reviewed and approved.</NotApprovedPrice>
                        }

                        <li style={{padding: "10px"}}>
                          Total Price for complete sign ready to install.
                          <p>
                            Order Today Ships FREE: <ShipDate format="long" productionDays={signProductionDays} />
                            &nbsp;
                            <button
                              onClick={() => setShowShipDateDisclaimer(!showShipDateDisclaimer)}
                              style={{
                                border: "0px",
                                backgroundColor: "transparent",
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "125%",
                              }}
                            >
                              *
                            </button>
                            {showShipDateDisclaimer && 
                              <p style={{color: "#999"}}>
                                You will be contacted within 1 business day by our production team to confirm your order. Failure
                                to respond to this call will add additional time to production as we cannot begin the build 
                                without confirmation.
                              </p>
                            }
                          </p>
                        </li>
                        {sign.custom_ind == 0 && 
                          <MenuItem color="orange">
                            <Link to={"/build/?uid=" + sign.uid}>
                              <FontAwesomeSvgIcon icon={faPencilAlt} />
                              Edit Sign
                            </Link>
                          </MenuItem>
                        }
                        <MenuItem color="blue">
                          <Link to={"/customizesign/?uid=" + sign.uid}>
                            <FontAwesomeSvgIcon icon={faUser} />
                            Free Design Help
                          </Link>
                        </MenuItem>                        
                        <MenuItems>
                          <ul>
                            <MenuItem>
                              <a href={process.env.GATSBY_API_BASE_URL + "/pdf/print?uid=" + sign.uid} target="_blank">
                                <FontAwesomeSvgIcon icon={faPrint} />
                                Print Sign     
                              </a>
                            </MenuItem>
                            <MenuItem>
                              <a href={process.env.GATSBY_API_BASE_URL + "/pdf/permit?uid=" + sign.uid} target="_blank">
                                <FontAwesomeSvgIcon icon={faUniversity} />
                                Permit Drawing     
                              </a>
                            </MenuItem>                            

                          </ul>
                        </MenuItems>
                        <MenuItem nohover={share} onClick={(e) => setShare(true)}>
                              <div>
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  <FontAwesomeSvgIcon icon={faShareAlt} />
                                  Share Sign
                                </a>
                                {share && 
                                  <div>
                                    <div>
                                      <TextField 
                                        value={data.link}
                                        wide
                                        error={errors.link === true}
                                        readonly
                                        id="link"
                                        name="link"
                                        label="Sign Link"
                                        onChange={onFieldChange} 
                                        style={{fontSize: "8pt"}}
                                      >
                                        <Button 
                                            label="Copy"
                                            color="blue"
                                            onClick={onCopyLink}
                                        />
                                      </TextField>
                                    </div>

                                    <p>Enter the email address of who you want to share this sign design with:</p>

                                    <div>
                                      <TextField 
                                        type="email"
                                        value={data.email}
                                        wide
                                        error={errors.email === true}
                                        required
                                        id="email"
                                        name="email"
                                        label="Email Address"
                                        onChange={onFieldChange} 
                                      >
                                        <Button 
                                            icon={shared ? faThumbsUp:faEnvelope}
                                            color={shared ? "green":"blue"}
                                            onClick={onShareLink}
                                            busy={sharing}
                                        />
                                      </TextField>
                                    </div>
                                  </div>
                                }
                              </div>
                            </MenuItem>

                      </SignMenu>
                    </div>
                </Layout2Columns>
              </Container>

              <Content>
              <Container>
                <SignDetails sign={sign} />
              </Container>
              </Content>
            </>
          :
            <div style={{ fontSize: "25px", textAlign: "center", margin: "50px", color: "#ccc"}}>
              Sign Not Found
            </div>
        }

        { (error && error != "Unable to locate the requested sign.") &&
            <MajorError 
              error={error} 
              log={error}
              onClose={() => setError(null)}
            />
        }
    </>
  )
}

export default SignPage
