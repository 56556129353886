import React from "react"
import styled from "styled-components"

import Field from "./Field"

const Input = styled.input`
    flex: 1; 
    max-width: ${props => props.wide ? "100%":"200px"}; 
    width: ${props => props.wide ? "100%":"auto"}; 
    font-size: ${props => props.large ? "24px" : props.small ? "12px": "18px"};
    padding: ${props => props.small ? 3:7}px;
    border: 0px;
`
 
const TextField = React.forwardRef((props, ref) => {
    function handleKeyDown(e) {
        if (e.key === 'Enter' && props.onEnter) {
            props.onEnter(e);
        }
    }
    
    return (
        <Field {...props}>
            <Input 
                id={props.id}
                type={props.type || "text"}
                autoComplete={props.autoComplete}
                ref={ref}
                wide={props.wide}
                disabled={props.disabled}
                readOnly={props.readonly}
                value={props.value}
                name={props.name}
                placeholder={props.placeholder}
                small={props.small}
                style={props.style}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyDown={handleKeyDown} 
            />
            {props.children}
        </Field>
    );
})

export default TextField